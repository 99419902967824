<template>
  <div class="stats content">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="sidebar sidebar-stats">
      <img class="sidebar-logo" src="../assets/Pro-tekt-logo.png" />
      <stats-controls
        v-on:stats-data-change="onStatsDataChange"
        v-on:devices="onDevicesChange"
        v-on:loading="onLoading"
        :device-id="deviceId"
        :date="date"
        :period="period"
      ></stats-controls>
    </div>
    <div class="stats-view-container">
      <b-row>
        <b-col
          v-for="layout in report_schema"
          v-bind:key="layout.title"
          :sm="layout.size.sm"
          :md="layout.size.md"
          :lg="layout.size.lg"
          class="stats-view"
        >
          <b-card>
            <b-card-header>{{ layout["title"] }}</b-card-header>
            <b-card-body class="summary-body">
              <div
                v-for="component in layout['components']"
                v-bind:key="component.name"
              >
                <daily-travel-chart
                  v-if="component['component_type'] == 'daily-travel-chart'"
                  v-bind:data="data"
                  v-bind:options="component['options']"
                />
                <stats-display-table
                  v-if="
                    component['component_type'] == 'daily-travel-summary-table'
                  "
                  v-bind:data="data"
                />
                <device-table-user
                  v-if="component['component_type'] == 'device-summary-table'"
                  v-bind:devices="devices"
                />
                <device-daily-table-user
                  v-if="component['component_type'] == 'device-daily-table'"
                  v-bind:devices="devices"
                  v-bind:deviceDaily="statsData['device-daily']"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import StatsControls from '../components/stats/StatsControls'
import StatsDisplayTable from '../components/stats/StatsDisplayTable'
import DeviceTableUser from '../components/stats/DeviceTableUser'
import DeviceDailyTableUser from '../components/stats/DeviceDailyTableUser'
import LoadingBox from '../components/helpers/LoadingBox'
import DailyTravelChart from '../components/stats/charts/DailyTravelChart.vue'

export default {
  name: 'stats-view',
  components: {
    StatsControls,
    StatsDisplayTable,
    DeviceTableUser,
    DeviceDailyTableUser,
    LoadingBox,
    DailyTravelChart
  },
  data: function () {
    return {
      statsData: {},
      data: [],
      devices: [],
      loading: true,
      deviceId: null,
      date: null,
      period: null,

      report_schema: [
        {
          title: 'Device Overview',
          size: {
            sm: 12,
            md: 12,
            lg: 12
          },
          components: [
            {
              name: 'device-summary-table',
              component_type: 'device-summary-table'
            }
          ]
        },
        {
          title: 'Total Travel Time',
          size: {
            sm: 12,
            md: 12,
            lg: 6
          },
          components: [
            {
              name: 'daily-travel-time-chart',
              component_type: 'daily-travel-chart',
              options: {
                show_time: true,
                show_distance: false,
                legend: false
              }
            },
            {
              name: 'daily-travel-time-summary-table',
              component_type: 'daily-travel-time-summary-table'
            }
          ]
        },
        {
          title: 'Total Travel Distance',
          size: {
            sm: 12,
            md: 12,
            lg: 6
          },
          components: [
            {
              name: 'daily-travel-distance-chart',
              component_type: 'daily-travel-chart',
              options: {
                show_time: false,
                show_distance: true,
                legend: false
              }
            },
            {
              name: 'daily-travel-distance-summary-table',
              component_type: 'daily-travel-distance-summary-table'
            }
          ]
        },
        {
          title: 'Daily Summary',
          size: {
            sm: 12,
            md: 12,
            lg: 12
          },
          components: [
            {
              name: 'device-summary_table',
              component_type: 'daily-travel-summary-table'
            }
          ]
        },
        {
          title: 'Export Daily Vehicle Breakdown',
          size: {
            sm: 12,
            md: 12,
            lg: 12
          },
          components: [
            {
              name: 'device-daily-table',
              component_type: 'device-daily-table'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.$route.query.hasOwnProperty('deviceId')) {
      this.deviceId = this.$route.query.deviceId
    }
    if (this.$route.query.hasOwnProperty('startDate')) {
      this.date = this.$route.query.startDate
    }
    if (this.$route.query.hasOwnProperty('period')) {
      this.period = this.$route.query.period
    }
  },
  methods: {
    onStatsDataChange: function (newData, oldData) {
      console.log('Data Change')
      this.statsData = newData
      console.log(this.statsData)

      // Convert our objects to Arrays for display.
      let dataArray = []
      for (let row in this.statsData.daily) {
        this.statsData.daily[row].date = row
        dataArray.push(this.statsData.daily[row])
      }
      console.log(dataArray)
      this.data = dataArray
    },
    onDevicesChange: function (newData, oldData) {
      console.log('Devices changed')
      console.log(newData)
      this.devices = newData
    },
    onLoading: function (newState, oldState) {
      this.loading = newState
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';


.content {
  display: flex;
  flex-direction: row-reverse;
  flex: 2;
  background-color: $default-content-background;
  overflow: auto;
}

.stats-view {
  align-self: center;
  padding: 1em;
  overflow-y: auto;
}

.stats-view .card .card-body{
  overflow-x: scroll;
}

.summary-body {
  padding: 0.5em;
}

.statistics-title {
  color: white;
}

.stats-view-container {
  height: 100vh;
  width: 90%;
  align-self: center;
  overflow-y: auto;
}
</style>
