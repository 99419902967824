<template>
  <div>
    <h1 class="super-statistic">
      <span class="statistic-highlight">{{ deviceList.length }}</span>
      Devices
      <b-button v-b-toggle.device-data variant="primary" size="lg">
        <i :class="$config.icons.general.dataTable"></i><br />
        <span class="when-open">Hide</span><span class="when-closed">Show</span> Detail
      </b-button>
    </h1>
    <b-collapse id="device-data">
      <div class="device-list">
        <loading-box v-bind:loading="loading"></loading-box>
        <b-table
          striped
          hover
          :items="deviceList"
          :fields="deviceFields"
          select-mode="single"
          selected-variant="success"
          :current-page="currentPage"
          :per-page="perPage"
          td-class="admin-device-table-data"
          th-class="admin-device-table-header"
          ref="deviceTable"
        >
          <!--Select All Header Element-->
          <template v-slot:head(selected)="row">
            <!--        <b-form-checkbox-->
            <!--          v-model="selectAll"-->
            <!--          v-on:change="toggleSelectAll"-->
            <!--        ></b-form-checkbox>-->
          </template>
          <!--Select Checkbox Row Element-->
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-model="row.item.selected"
              v-on:change="selectChange(row.item, $event)"
            ></b-form-checkbox>
          </template>
          <!-- Geolimit -->
          <template v-slot:cell(geolimit_status)="row">
            <p v-if="row.item.geolimit_status.is_active">On</p>
            <p v-if="!row.item.geolimit_status.is_active">Off</p>
          </template>
          <!--Device Details row-detail element-->
          <template v-slot:row-details="row">
            <b-card>
              Device List Details
              <b-button size="sm" @click="row.toggleDetails"
                >Hide Details</b-button
              >
            </b-card>
          </template>
          <!-- Jump to fleet screen -->
          <template v-slot:cell(map)="row">
            <b-button
              class="button w-100"
              v-if="row.item.device_imei"
              @click="deviceActionFleet(row.item.device_imei)"
              >Show</b-button
            >
            <b-button
              class="button w-100"
              v-else
              @click="deviceActionFleet(row.item.device_imei)"
              >Show</b-button
            >
          </template>
        </b-table>

        <p v-if="loadingMoreDevices">Loading more devices...</p>
        <div class="green-divider" v-if="!hideFilter"></div>
        <div class="page-bar" v-if="!hidePagination">
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            class="page-select"
            v-if="totalRows > Math.min(pageOptions.map((x) => x.value))"
          >
          </b-form-select>
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="page-buttons"
          ></b-pagination>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import LoadingBox from '../helpers/LoadingBox'
import moment from 'moment'

export default {
  name: 'device-table-user',
  components: {
    LoadingBox
  },
  props: {
    devices: Array, // If provided then the table will show these devices, rather than a list from the API. Also disabled advanced filters
    hideFilter: Boolean, // Hides the entire filter bar, for embedded tables.
    hidePagination: Boolean, // Hide the pagination controls
    hideCheckboxes: Boolean, // Hide the multi-select controls
    hideAdvanced: Boolean, // Hide the advanced filter options, leaving the plain text filter.
    showFields: Array // An Array of strings which match the 'key' values for possible fields. (see deviceFields)
  },
  data: function () {
    return {
      loading: true,
      // Assignment Temporary variables
      tempOwner: null,
      // Table Filtering
      filterMethod: 'remote', // Filter Locally or remotely at the server-side
      filter: '',
      filterOn: [
        'imei',
        'device_id',
        'name',
        'plant_num',
        'registration',
        'device_phone'
      ],
      // Table Pagination (local pagination - on the browser)
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      selectAll: false,
      currentDevice: null,
      showAdvanced: false,
      deviceFields: [
        { key: 'device_code', label: 'Code', sortable: true },
        { key: 'device_name', label: 'Name', sortable: true },
        { key: 'plant_num', label: 'Plant', sortable: true },
        { key: 'registration', label: 'Rego', sortable: true },
        {
          key: 'location.datetime',
          label: 'Last Report',
          sortable: true,
          formatter: value => {
            if (value) {
              return this.formatTimestamp(value)
            } else {
              return 'N/A'
            }
          }
        },
        {
          key: 'next_service',
          label: 'Next Service',
          sortable: true,
          formatter: value => {
            if (value) {
              return this.formatTimestamp(value * 1000)
            } else {
              return ''
            }
          }
        },
        {
          key: 'overspeed',
          label: 'Overspeed Alerts',
          formatter: (value, key, item) => {
            if (item.events) {
              return item.events.OVERSPEED_ALARM
            } else {
              return ''
            }
          }
        },
        {
          key: 'geolimit',
          label: 'Geolimit breaches',
          formatter: (value, key, item) => {
            if (item.events) {
              return item.events.GEOLIMIT_OUT
            } else {
              return ''
            }
          }
        },
        {
          key: 'maximum_speed',
          label: 'Maximum Speed',
          formatter: value => {
            if (value) {
              return value.toFixed(0) + 'km/h'
            } else {
              return ''
            }
          }
        },
        // {
        //   key: "events",
        //   label: "Events",
        //   formatter: (value, key, item) => {
        //     if (item.events) {
        //       return item.events;
        //     } else {
        //       return "N/A";
        //     }
        //   }
        // },
        {
          key: 'map',
          label: 'Map'
        }
      ],
      deviceList: [],
      loadingMoreDevices: false
    }
  },
  async created () {
    // Check if the 'devices' prop has been provided, if so use that rather than fetching a full list.
    if (this.devices) {
      this.showAdvanced = false
    }
    this.loading = false
  },
  methods: {
    updateTableFromJSON: function (deviceData) {
      this.deviceList = []
      this.deviceList.push(...deviceData)
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).format('YYYY-MM-DD HH:mm')
    },
    deviceActionFleet: function (deviceImei) {
      let routeData = this.$router.resolve(`/device/${deviceImei}`)
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    devices: function (newVal, oldVal) {
      this.updateTableFromJSON(newVal)
    },
    showFields: function (newVal, oldVal) {
      this.updateFields(newVal)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.user-list {
  position: relative;
}

.filter-bar {
  display: flex;
  flex-direction: row;
}

.page-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
}

.page-select {
  width: 10%;
  min-width: 50px;
}

.page-buttons {
  width: 25%;
  min-width: 150px;
}

.row-action {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.advanced-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px;
  padding: 3px;
  background: $theme-color-background-1;
  border: 1px solid $theme-color-primary-3;
}

.filter-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0px 5px;
}

.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: calc(1.5em + 0.5rem + 2px);
}

</style>
