<template>
  <div class="summary-body">
    <div v-if="noData" class="mb-1 font-italic">No Data to Display</div>
    <div class="chart-parent">
      <div v-if="!noData" class="stats-graph">
        <bar-graph v-bind:chart-data="chartData" v-bind:options="chartOpts"></bar-graph>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BarGraph from './BarGraph'

export default {
  name: 'daily-travel-chart',
  components: { BarGraph },
  props: {
    data: Array,
    options: Object
  },
  data: function () {
    return {
      noData: true,
      chartData: [],
      chartOpts: {}
    }
  },
  mounted () {
  },
  methods: {
    formatTimestamp (timestamp) {
      return moment.unix(timestamp).format('HH:mm')
    },
    formatDay (day) {
      return moment(day).format('dddd Do')
    },
    formatFullDay (day) {
      return moment(day).format('YY/MM/DD')
    },
    formatKm (dist) {
      if (typeof dist !== 'number') {
        dist = parseFloat(dist)
      }
      return dist.toFixed(2) + ' km'
    },
    formatKmh (dist) {
      return Math.round(dist) + ' km/h'
    },
    formatSecs (seconds) {
      let duration = moment.duration(seconds, 'seconds')
      return moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    },
    clickDevice: function (device) {
      this.$router.push({ path: 'device/' + device.device_code })
    },
    updateChart: function (data) {
      let labels = []
      let kmData = []
      let hoursData = []

      data.forEach(day => {
        labels.push(day.date)
        kmData.push(day.kms_travelled)
        hoursData.push(day.moving_time / 60)
      })

      let chartColors = {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: '#fff500',
        green: '#38d429',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(201, 203, 207)'
      }

      let datasets = []

      if (this.options.show_distance) {
        datasets.push({
          label: 'Distance [kms]',
          fontColor: '#fff',
          borderColor: chartColors.green,
          backgroundColor: chartColors.yellow,
          fill: false,
          data: kmData,
          yAxisID: 'y-axis-1'
        })
      }
      if (this.options.show_time) {
        datasets.push({
          label: 'Time [m]',
          borderColor: chartColors.yellow,
          fontColor: '#fff',
          backgroundColor: chartColors.green,
          fill: false,
          data: hoursData,
          yAxisID: 'y-axis-2'
        })
      }

      this.chartData = {
        labels: labels,
        datasets: datasets
      }

      this.chartOpts = {
        legend: {
          // If you set this.options['legend'] to true, this is overridden with legend info
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hoverMode: 'index',
        stacked: false,
        title: {
          display: true,
          text: 'Daily Analysis',
          fontColor: '#ffffff'
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: (tooltipItem, data) => {
              console.log(tooltipItem)
              // TODO: Support when we show both distance and time together
              if (this.options.show_distance) {
                // Hours travelled
                return 'Distance: ' + `${parseInt(tooltipItem.value)} kms`
              } else {
                return 'Time: ' + `${this.formatSecs(tooltipItem.value * 60)}`
              }
            }
          },
          backgroundColor: '#FFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: '#ffffff'
            },
            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
            display: true,
            position: 'left',
            id: 'y-axis-1'
          }, {
            ticks: {
              fontColor: '#ffffff'
            },
            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
            display: true,
            fontColor: '#fff',
            position: 'right',
            id: 'y-axis-2',

            // grid line settings
            gridLines: {
              drawOnChartArea: false // only want the grid lines for one axis to show up
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: '#ffffff'
            }
          }]
        }
      }
      if (this.options.legend) {
        this.chartOpts['legend'] = {
          labels: {
            fontColor: '#ffffff'
          }
        }
      }
    }
  },
  watch: {
    data: function (newData, oldData) {
      console.log('Table has data!', newData)
      this.tableData = newData

      if (this.tableData.length === 0) {
        this.noData = true
      } else {
        this.noData = false
        this.updateChart(newData)
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/variables';

</style>
