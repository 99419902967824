var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stats-controls" }, [
    _c("div", [
      _c("h1", { staticClass: "sidebar-heading" }, [
        _vm._v("Statistics Options")
      ]),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        { staticClass: "device-blocks" },
        [
          _c("div", { staticClass: "device-item" }, [
            _c(
              "p",
              { staticClass: "menu-item-heading" },
              [
                _c("b-form-select", {
                  staticClass: "text-input",
                  attrs: {
                    options: _vm.reportFrequencyOptions,
                    placeholder: "Select Frequency"
                  },
                  model: {
                    value: _vm.reportFrequency,
                    callback: function($$v) {
                      _vm.reportFrequency = $$v
                    },
                    expression: "reportFrequency"
                  }
                })
              ],
              1
            )
          ]),
          _vm.reportFrequency === "weekly"
            ? _c(
                "div",
                { staticClass: "device-item" },
                [
                  _c("p", { staticClass: "menu-item-heading" }, [
                    _vm._v("Week Starting:")
                  ]),
                  _c("b-form-datepicker", {
                    staticClass: "text-input date-picker z-2",
                    attrs: {
                      type: "date",
                      size: "sm",
                      placeholder: "YYYY/MM/DD"
                    },
                    model: {
                      value: _vm.selectedDate,
                      callback: function($$v) {
                        _vm.selectedDate = $$v
                      },
                      expression: "selectedDate"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "row justify-content-between px-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "control-icon",
                          on: {
                            click: function($event) {
                              return _vm.prevWeek()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-step-backward" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "control-icon",
                          on: {
                            click: function($event) {
                              return _vm.nextWeek()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-step-forward" })]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.reportFrequency === "monthly"
            ? _c("div", { staticClass: "device-item" }, [
                _c(
                  "p",
                  { staticClass: "menu-item-heading" },
                  [
                    _vm._v(" Month Select: "),
                    _c("b-form-select", {
                      staticClass: "text-input",
                      attrs: {
                        options: _vm.reportMonths,
                        placeholder: "YYYY/MM/DD"
                      },
                      model: {
                        value: _vm.selectedMonth,
                        callback: function($$v) {
                          _vm.selectedMonth = $$v
                        },
                        expression: "selectedMonth"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "row justify-content-between px-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "control-icon",
                      on: {
                        click: function($event) {
                          return _vm.prevMonth()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-step-backward" })]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "control-icon",
                      on: {
                        click: function($event) {
                          return _vm.nextMonth()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-step-forward" })]
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "device-item mt-2" }, [
            _c("p", { staticClass: "menu-item-heading" }, [
              _vm._v("Select Device(s):")
            ]),
            _c(
              "div",
              { staticClass: "device-select" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      indeterminate: _vm.indeterminate,
                      "aria-describedby": "devices",
                      "aria-controls": "devices"
                    },
                    on: { change: _vm.toggleAll },
                    model: {
                      value: _vm.allSelected,
                      callback: function($$v) {
                        _vm.allSelected = $$v
                      },
                      expression: "allSelected"
                    }
                  },
                  [_vm._v(" All Devices ")]
                ),
                _c("b-form-checkbox-group", {
                  attrs: {
                    id: "devices",
                    options: _vm.deviceList,
                    "value-field": "imei",
                    "text-field": "name",
                    name: "devices",
                    "aria-label": "Select Devices",
                    stacked: ""
                  },
                  model: {
                    value: _vm.selectedDevices,
                    callback: function($$v) {
                      _vm.selectedDevices = $$v
                    },
                    expression: "selectedDevices"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "trip-purpose-group",
                "label-for": "trip-purpose-select"
              }
            },
            [
              _c("p", { staticClass: "menu-item-heading" }, [
                _vm._v("Trip purpose:")
              ]),
              _c("b-form-select", {
                attrs: {
                  id: "trip-purpose-select",
                  options: _vm.tripPurposeOptions
                },
                model: {
                  value: _vm.tripPurpose,
                  callback: function($$v) {
                    _vm.tripPurpose = $$v
                  },
                  expression: "tripPurpose"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "tag-filters-group",
                "label-for": "tag-filter-select"
              }
            },
            [
              _c("p", { staticClass: "menu-item-heading" }, [
                _vm._v("Tag Filters:")
              ]),
              _c("b-form-select", {
                attrs: {
                  id: "trip-purpose-select",
                  options: _vm.tagFilterOptions
                },
                model: {
                  value: _vm.tagFilter,
                  callback: function($$v) {
                    _vm.tagFilter = $$v
                  },
                  expression: "tagFilter"
                }
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "button w-100",
              on: {
                click: function($event) {
                  return _vm.clickAnalyse()
                }
              }
            },
            [_vm._v("Analyse")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }