<template>
  <div>
    <b-button v-b-toggle.device-daily-data variant="primary" size="lg">
      <i :class="$config.icons.general.dataTable"></i><br />
      <span class="when-open">Hide</span
      ><span class="when-closed">Show</span> Report
    </b-button>

    <b-button @click="exportCSV(deviceDailyData, 'Device Daily')" size="lg">
      <i :class="$config.icons.stats.exportCSVFile"></i><br />
      Export as CSV
    </b-button>
    <br />
    <b-collapse id="device-daily-data">
      <b-table
        striped
        hover
        :items="deviceDailyData"
        :fields="dailyFields"
        select-mode="single"
        selected-variant="success"
        tdClass="bv-table-data"
        th-class="bv-table-header"
      ></b-table>
    </b-collapse>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'stats-display-table',
  props: {
    data: Array,
    deviceDaily: Object,
    devices: Array
  },
  data: function () {
    return {
      dailyFields: [
        {
          key: 'device_imei',
          label: 'Device Name',
          sortable: true,
          formatter: this.formatDeviceName
        },
        {
          key: 'date',
          label: 'Day',
          sortable: true,
          formatter: this.formatDay
        },
        {
          key: 'start_time',
          label: 'Start',
          sortable: true,
          formatter: this.formatTimestamp
        },
        {
          key: 'end_time',
          label: 'End',
          sortable: true,
          formatter: this.formatTimestamp
        },
        {
          key: 'kms_travelled',
          label: 'Drive Distance',
          sortable: true,
          formatter: this.formatKm
        },
        {
          key: 'moving_time',
          label: 'Drive Time',
          sortable: true,
          formatter: this.formatSecs
        },
        {
          key: 'maximum_speed',
          label: 'Top Speed',
          sortable: true,
          formatter: this.formatKmh
        }
      ],
      chartData: [],
      chartOpts: {},
      summaryData: [],
      summaryFields: [
        { key: 'stat', label: 'Statistic', sortable: true },
        {
          key: 'kms_travelled',
          label: 'Drive Distance',
          sortable: true,
          formatter: this.formatKm
        },
        {
          key: 'moving_time',
          label: 'Drive Time',
          sortable: true,
          formatter: this.formatSecs
        },
        {
          key: 'maximum_speed',
          label: 'Top Speed',
          sortable: true,
          formatter: this.formatKmh
        }
      ]
    }
  },
  computed: {
    deviceDailyData () {
      let byDeviceByDay = []

      for (let deviceImei in this.deviceDaily) {
        for (let date in this.deviceDaily[deviceImei]) {
          let row = this.deviceDaily[deviceImei][date]
          row['device_imei'] = deviceImei
          row['date'] = date
          byDeviceByDay.push(row)
        }
      }
      return byDeviceByDay
    },
    deviceNames () {
      let devicesByImei = {}
      for (let deviceNumber in this.devices) {
        let imei = this.devices[deviceNumber]['device_imei']
        devicesByImei[imei] = this.devices[deviceNumber]
      }
      return devicesByImei
    }
  },
  mounted () {},
  methods: {
    formatDeviceName (deviceImei) {
      if (this.deviceNames[deviceImei]) {
        return this.deviceNames[deviceImei]['device_name']
      } else {
        return deviceImei
      }
    },
    formatTimestamp (timestamp) {
      return moment.unix(timestamp).format('HH:mm')
    },
    formatTimestampISO (timestamp) {
      return moment.unix(timestamp).format()
    },
    formatDay (day) {
      return moment(day).format('dddd Do')
    },
    formatFullDay (day) {
      return moment(day).format('YY/MM/DD')
    },
    formatFullDayISO (day) {
      return moment(day).format('YYYY-MM-DD')
    },
    formatKm (dist) {
      if (typeof dist !== 'number') {
        dist = parseFloat(dist)
      }
      return dist.toFixed(2) + ' km'
    },
    formatKmh (dist) {
      return Math.round(dist) + ' km/h'
    },
    formatSecs (seconds) {
      let duration = moment.duration(seconds, 'seconds')
      return moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    },
    clickDevice: function (device) {
      this.$router.push({ path: 'device/' + device.device_code })
    },
    updateChart: function (data) {
      let labels = []
      let kmData = []
      let hoursData = []

      data.forEach(day => {
        labels.push(day.date)
        kmData.push(day.kms_travelled)
        hoursData.push(day.moving_time / 60)
      })

      let chartColors = {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: '#fff500',
        green: '#38d429',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(201, 203, 207)'
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Distance [kms]',
            fontColor: '#fff',
            borderColor: chartColors.green,
            backgroundColor: chartColors.yellow,
            fill: false,
            data: kmData,
            yAxisID: 'y-axis-1'
          },
          {
            label: 'Time [m]',
            borderColor: chartColors.yellow,
            fontColor: '#fff',
            backgroundColor: chartColors.green,
            fill: false,
            data: hoursData,
            yAxisID: 'y-axis-2'
          }
        ]
      }

      this.chartOpts = {
        legend: {
          labels: {
            fontColor: '#ffffff'
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        hoverMode: 'index',
        stacked: false,
        title: {
          display: true,
          text: 'Daily Analysis',
          fontColor: '#ffffff'
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: (tooltipItem, data) => {
              if (tooltipItem.datasetIndex === 0) {
                // Hours travelled
                return 'Distance: ' + `${parseInt(tooltipItem.value)} kms`
              } else {
                return 'Time: ' + `${this.formatSecs(tooltipItem.value * 60)}`
              }
            }
          },
          backgroundColor: '#FFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: '#ffffff'
              },
              type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: 'left',
              id: 'y-axis-1'
            },
            {
              ticks: {
                fontColor: '#ffffff'
              },
              type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              fontColor: '#fff',
              position: 'right',
              id: 'y-axis-2',

              // grid line settings
              gridLines: {
                drawOnChartArea: false // only want the grid lines for one axis to show up
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: '#ffffff'
              }
            }
          ]
        }
      }
    },
    generateSummary: function (newData) {
      let distanceSum = newData.reduce((a, b) => a + b.kms_travelled, 0) // NOTE: 0 is the initial value
      let timeSum = newData.reduce((a, b) => a + b.moving_time, 0)
      let maxSpeed = newData.reduce((a, b) => {
        if (a > b.maximum_speed) {
          return a
        } else {
          return b.maximum_speed
        }
      }, 0)

      let distanceAvg = distanceSum / newData.length
      let timeAvg = timeSum / newData.length
      this.summaryData = [
        {
          stat: 'Daily Average',
          kms_travelled: distanceAvg,
          moving_time: timeAvg,
          maximum_speed: maxSpeed
        },
        {
          stat: 'Total',
          kms_travelled: distanceSum,
          moving_time: timeSum,
          maximum_speed: maxSpeed
        }
      ]
    },
    exportCSV: function (data, prefix) {
      // PapaParse Configuration
      let config = {
        quotes: false, // or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false, // or 'greedy',
        columns: null // or array of strings
      }

      let exportData = []
      // To avoid contaminating the source data we need to create new objects to export, since we will be
      // applying formatting to the values to make them human-readable.
      for (let x in data) {
        let row = {}
        if (data[x].hasOwnProperty('device_imei')) {
          row.device_name = this.formatDeviceName(data[x].device_imei)
        }
        if (data[x].hasOwnProperty('date')) {
          row.date = this.formatFullDayISO(data[x].date)
        }
        if (data[x].hasOwnProperty('start_time')) {
          row.start_time = this.formatTimestamp(data[x].start_time)
        }
        if (data[x].hasOwnProperty('end_time')) {
          row.end_time = this.formatTimestamp(data[x].end_time)
        }
        if (data[x].hasOwnProperty('kms_travelled')) {
          row.kms_travelled = this.formatKm(data[x].kms_travelled)
        }
        if (data[x].hasOwnProperty('moving_time')) {
          row.moving_time = this.formatSecs(data[x].moving_time)
        }
        if (data[x].hasOwnProperty('maximum_speed')) {
          row.maximum_speed = this.formatKmh(data[x].maximum_speed)
        }
        exportData.push(row)
      }
      let csvData = this.$papa.unparse(exportData, config)
      this.$papa.download(csvData, prefix + ' Report')
    }
  },
  watch: {
    data: function (newData, oldData) {
      console.log('Table has data!', newData)
      this.tableData = newData

      if (this.tableData.length === 0) {
        this.noData = true
      } else {
        this.noData = false
        this.updateChart(newData)
        this.generateSummary(newData)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';
</style>
