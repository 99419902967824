<template>
  <div class="stats-controls">
    <div>
      <h1 class="sidebar-heading">Statistics Options</h1>
      <div class="green-divider"></div>
      <div class="device-blocks">
        <div class="device-item">
          <p class="menu-item-heading">
            <b-form-select
              class="text-input"
              v-model="reportFrequency"
              :options="reportFrequencyOptions"
              placeholder="Select Frequency"
            >
            </b-form-select>
          </p>
        </div>
        <div class="device-item" v-if="reportFrequency === 'weekly'">
          <p class="menu-item-heading">Week Starting:</p>
          <b-form-datepicker
            type="date"
            class="text-input date-picker z-2"
            v-model="selectedDate"
            size="sm"
            placeholder="YYYY/MM/DD"
          >
          </b-form-datepicker>
          <div class="row justify-content-between px-3">
            <div class="control-icon" @click="prevWeek()">
              <i class="fa fa-step-backward"></i>
            </div>
            <div class="control-icon" @click="nextWeek()">
              <i class="fa fa-step-forward"></i>
            </div>
          </div>
        </div>
        <div class="device-item" v-if="reportFrequency === 'monthly'">
          <p class="menu-item-heading">
            Month Select:
            <b-form-select
              class="text-input"
              v-model="selectedMonth"
              :options="reportMonths"
              placeholder="YYYY/MM/DD"
            >
            </b-form-select>
          </p>
          <div class="row justify-content-between px-3">
            <div class="control-icon" @click="prevMonth()">
              <i class="fa fa-step-backward"></i>
            </div>
            <div class="control-icon" @click="nextMonth()">
              <i class="fa fa-step-forward"></i>
            </div>
          </div>
        </div>
        <!--        <div class="device-item" v-if="reportFrequency==='monthly'">-->
        <!--          <p class="menu-item-heading">Month Select:-->
        <!--            <b-form-select class="text-input" v-model="selectedMonth" :options="reportMonths" placeholder="YYYY/MM/DD"></b-form-select>-->
        <!--            <b-form-select class="text-input" v-model="selectedYear" :options="reportYears" placeholder="YYYY/MM/DD"></b-form-select>-->
        <!--          </p>-->
        <!--        </div>-->
        <div class="device-item mt-2">
          <p class="menu-item-heading">Select Device(s):</p>
          <div class="device-select">
            <b-form-checkbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              aria-describedby="devices"
              aria-controls="devices"
              @change="toggleAll"
            >
              All Devices
            </b-form-checkbox>
            <b-form-checkbox-group
              id="devices"
              v-model="selectedDevices"
              :options="deviceList"
              value-field="imei"
              text-field="name"
              name="devices"
              aria-label="Select Devices"
              stacked
            ></b-form-checkbox-group>
          </div>
        </div>
        <b-form-group id="trip-purpose-group" label-for="trip-purpose-select">
          <p class="menu-item-heading">Trip purpose:</p>
          <b-form-select
            id="trip-purpose-select"
            v-model="tripPurpose"
            :options="tripPurposeOptions"
          />
        </b-form-group>
        <b-form-group id="tag-filters-group" label-for="tag-filter-select">
          <p class="menu-item-heading">Tag Filters:</p>
          <b-form-select
            id="trip-purpose-select"
            v-model="tagFilter"
            :options="tagFilterOptions"
          />
        </b-form-group>
        <b-button class="button w-100" @click="clickAnalyse()"
          >Analyse</b-button
        >
      </div>
      <!--      <b-card class="mt-10">-->
      <!--        <b-card-header>Summary</b-card-header>-->
      <!--        <b-card-body class="summary-body flex">-->
      <!--            <div>Distance: {{summaryDistance}} Km</div>-->
      <!--            <div>Hours: {{summaryHours}}</div>-->
      <!--            <div>Trip Count: {{summaryTripCount}}</div>-->
      <!--            <div># Devices: {{summaryDeviceCount}}</div>-->
      <!--        </b-card-body>-->
      <!--      </b-card>-->
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import moment from 'moment'

export default {
  name: 'stats-controls',
  props: ['deviceId', 'date', 'period'],
  data: function () {
    return {
      reportFrequency: 'weekly',
      reportFrequencyOptions: [
        {
          value: 'weekly',
          text: 'Weekly'
        },
        {
          value: 'monthly',
          text: 'Monthly'
        }
      ],
      reportMonths: [],
      selectedMonth: moment(new Date())
        .startOf('month')
        .format('YYYY-MM-DD'),
      selectedYear: null,
      reportYears: [],
      selectedDevices: [],
      allSelected: false,
      indeterminate: false,
      loading: true,
      selectedDate: moment(new Date())
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      // Placeholder for device info
      deviceList: [],
      summaryDistance: 0,
      summaryHours: 0,
      summaryTripCount: 0,
      summaryDeviceCount: 0,
      // Trip purpose selection,
      tripPurposeOptions: [
        {
          value: 'any',
          text: 'Work or Personal'
        },
        {
          value: 'work',
          text: 'Work Use Only'
        },
        {
          value: 'personal',
          text: 'Personal Use'
        }
      ],
      tripPurpose: 'any',
      tagFilterOptions: [
        {
          text: 'None',
          value: null
        }
      ],
      tagFilter: null
    }
  },
  created () {
    this.$emit('loading', true)
    this.tagFilterOptions = this.$config.trips.tags.map(x => {
      return {
        text: x.title,
        value: x.propName
      }
    })
    this.tagFilterOptions.push({
      text: 'None',
      value: null
    })
    try {
      DataProvider.getDeviceList().then(res => {
        // Check if our query was successful
        if (!res.success) {
          ErrorHelper.displayDataErrorToast(res)
          return // abort!
        }

        // Generate a list of months, starting with our configured start date to this month.
        let today = moment(new Date())
        let startDate = moment(this.$config.stats.reportStartDate)
        let years = []
        while (startDate.isBefore(today)) {
          years.push({
            value: startDate.startOf('year').format('YYYY'),
            text: startDate.startOf('year').format('YYYY')
          })
          startDate.add(1, 'year')
        }
        this.reportYears = years

        let data = []
        // Convert props into an Array for convenience
        for (let item in res.data) {
          data.push(res.data[item])
        }
        this.deviceList = data
        this.loading = false
        // Check if we've been passed any parameters from the stats page. These should be any Queryparams passed through
        // from the Stats view component
        if (this.deviceId || this.date || this.period) {
          this.paramLoad()
        } else {
          this.selectAll()
          this.clickAnalyse()
        }
        this.$emit('loading', false)
      })
    } catch (err) {
      console.error(err)
      ErrorHelper.displayGeneralErrorToast(
        'An Error Occurred Getting a Device List from the Server.',
        'Server Communication Error'
      )
    }
    // Generate a list of months, starting with our configured start date to this month.
    let today = moment(new Date())
    let startDate = moment(this.$config.stats.reportStartDate)
    let monthCount = 0
    let months = []
    while (
      startDate.isBefore(today) &&
      monthCount < this.$config.stats.reportMonthsCap
    ) {
      months.push({
        value: startDate.startOf('month').format('YYYY-MM-DD'),
        text: startDate.format('MMMM-YYYY')
      })
      startDate.add(1, 'month')
      monthCount++
    }
    this.reportMonths = months.reverse()
  },
  methods: {
    paramLoad: function () {
      if (this.deviceId) {
        // Check to see if we've received a device ID
        let device = this.deviceList.find(
          x =>
            x.device_id.toString().toUpperCase() === this.deviceId.toUpperCase()
        )
        if (device === undefined) {
          ErrorHelper.displayGeneralErrorToast(
            "We can't find the device you're trying to view. Check the device ID and that you have permission to view it.",
            'Device Not Found'
          )
          return
        }
        device.selected = true
        this.selectedDevices.push(device.device_imei)
      }
      if (this.period) {
        // Check if a report period has been specified
        // TODO - Make the below code nicer and more extensible for other report types.
        if (this.period === 'weekly') {
          this.reportFrequency = this.period
          this.selectedDate = moment(new Date())
            .startOf('week')
            .format('YYYY-MM-DD')
        } else if (this.period === 'monthly') {
          this.reportFrequency = this.period
          this.selectedMonth = moment(new Date())
            .startOf('month')
            .format('YYYY-MM-DD')
        } else {
          ErrorHelper.displayGeneralErrorToast(
            'An invalid reporting period was specified in the URL!',
            'Invalid Report Period'
          )
        }
      }
      if (this.date) {
        // Check to see if we also received at date
        if (this.date && moment(this.date).isValid()) {
          if (this.reportFrequency === 'monthly') {
            // if we're running a monthly report set the start date to the first of the month.
            this.selectedMonth = moment(this.date)
              .startOf('month')
              .format('YYYY-MM-DD')
          } else {
            // Allow Other report to be run for whatever day is given.
            this.selectedDate = moment(this.date).format('YYYY-MM-DD')
          }
        } else if (!moment(this.date).isValid()) {
          ErrorHelper.displayGeneralErrorToast(
            'Invalid Date Provided in Query Parameters.',
            'Invalid Date Selection'
          )
          return
        }
      }
      if (this.selectedDevices.length === 0) {
        console.log('Checked all devices')
        this.toggleAll(true)
      }
      this.clickAnalyse()
    },
    clickDevice: function (device) {
      this.$router.push({ path: 'device/' + device.device_code })
    },
    clickAnalyse: async function () {
      this.$emit('loading', true)
      let startUTC, endDate, endUTC
      if (this.reportFrequency === 'weekly') {
        startUTC = moment(
          moment(this.selectedDate).format('YYYY-MM-DD') + ' 00:00'
        ).utc()
        endDate = moment(this.selectedDate).add(7, 'days')
        endUTC = moment(moment(endDate).format('YYYY-MM-DD') + ' 23:59').utc()
      } else if (this.reportFrequency === 'monthly') {
        startUTC = moment(moment(this.selectedMonth).startOf('month')).utc()
        endUTC = moment(moment(this.selectedMonth).endOf('month')).utc()
      }
      console.log('StartDate: ' + startUTC)
      console.log('EndDate: ' + endUTC)
      let statsData = await DataProvider.getStatistics(
        this.selectedDevices,
        startUTC,
        endUTC,
        this.tripPurpose,
        this.tagFilter
      )
      console.log('statsData')
      console.log(statsData.data)
      if (statsData.success) {
        this.summaryDistance = Math.round(statsData.data.total_kms_travelled)
        this.summaryHours = Math.round(
          statsData.data.total_seconds_travelled / 3600
        )
        this.summaryTripCount = statsData.data.total_trips
        this.summaryDeviceCount = statsData.data.number_devices

        if (Object.keys(statsData.data.daily).length === 0) {
          this.$bvToast.toast(
            "We didn't find any data for that vehicle in the specified period.",
            {
              title: 'No Data Found',
              variant: 'warning',
              toaster: 'b-toaster-top-center'
            }
          )
        }

        this.$emit('stats-data-change', statsData.data)
        this.$emit('devices', statsData.data.devices)
        this.$emit('loading', false)
      } else {
        ErrorHelper.displayDataErrorToast(statsData)
        this.$emit('loading', false)
      }
    },
    nextWeek: function () {
      let week = moment(this.selectedDate).add(7, 'days')
      if (week.isBefore(moment(new Date()))) {
        this.selectedDate = week.format('YYYY-MM-DD')
      }
    },
    prevWeek: function () {
      let week = moment(this.selectedDate).subtract(7, 'days')
      if (week.isAfter(this.$config.stats.reportStartDate)) {
        this.selectedDate = week.format('YYYY-MM-DD')
      }
    },
    nextMonth: function () {
      console.log(this.selectedMonth)
      let month = moment(this.selectedMonth)
        .add(1, 'months')
        .startOf('month')
      if (month.isBefore(moment(new Date()))) {
        this.selectedMonth = month.format('YYYY-MM-DD')
        console.log(this.selectedMonth)
      }
    },
    prevMonth: function () {
      console.log(this.selectedMonth)
      let month = moment(this.selectedMonth)
        .subtract(1, 'months')
        .startOf('month')
      if (month.isAfter(this.$config.stats.reportStartDate)) {
        this.selectedMonth = month.format('YYYY-MM-DD')
        console.log(this.selectedMonth)
      }
    },
    toggleAll: function (checked) {
      console.log(checked)
      this.selectedDevices = checked ? this.deviceList.map(x => x.device_imei) : []
    },
    selectAll: function () {
      console.log('Selecting all')
      this.selectedDevices = this.deviceList.map(x => x.device_imei)
    }
  },
  watch: {
    selectedDevices (newVal, oldVal) {
      // Handle changes in device selection
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.deviceList.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.menu-item-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif;
  color: $text-color-invert;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.right_menu_item {
  text-align: left;
}

.right_menu_button {
  color: $text-color-invert;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.right_menu_button::before {
  font-size: 0.8em;
  content: "> ";
  color: red;
}

.device-select {
  background-color: $theme-color-background-1;
  text-align: left;
}

.option {
  color: $text-color-default;
}

.summary-body {
  padding: 1rem;
}

.z-2 {
  z-index: 2;
}

.date-picker-hack {
  width: 500px;
}
</style>
