var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.device-daily-data",
              modifiers: { "device-daily-data": true }
            }
          ],
          attrs: { variant: "primary", size: "lg" }
        },
        [
          _c("i", { class: _vm.$config.icons.general.dataTable }),
          _c("br"),
          _c("span", { staticClass: "when-open" }, [_vm._v("Hide")]),
          _c("span", { staticClass: "when-closed" }, [_vm._v("Show")]),
          _vm._v(" Report ")
        ]
      ),
      _c(
        "b-button",
        {
          attrs: { size: "lg" },
          on: {
            click: function($event) {
              return _vm.exportCSV(_vm.deviceDailyData, "Device Daily")
            }
          }
        },
        [
          _c("i", { class: _vm.$config.icons.stats.exportCSVFile }),
          _c("br"),
          _vm._v(" Export as CSV ")
        ]
      ),
      _c("br"),
      _c(
        "b-collapse",
        { attrs: { id: "device-daily-data" } },
        [
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              items: _vm.deviceDailyData,
              fields: _vm.dailyFields,
              "select-mode": "single",
              "selected-variant": "success",
              tdClass: "bv-table-data",
              "th-class": "bv-table-header"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }