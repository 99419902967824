var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "summary-body" }, [
    _vm.noData
      ? _c("div", { staticClass: "mb-1 font-italic" }, [
          _vm._v("No Data to Display")
        ])
      : _vm._e(),
    _c("div", { staticClass: "chart-parent" }, [
      !_vm.noData
        ? _c(
            "div",
            { staticClass: "stats-graph" },
            [
              _c("bar-graph", {
                attrs: { "chart-data": _vm.chartData, options: _vm.chartOpts }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }