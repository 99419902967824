var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticClass: "super-statistic" },
        [
          _c("span", { staticClass: "statistic-highlight" }, [
            _vm._v(_vm._s(_vm.deviceList.length))
          ]),
          _vm._v(" Devices "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.device-data",
                  modifiers: { "device-data": true }
                }
              ],
              attrs: { variant: "primary", size: "lg" }
            },
            [
              _c("i", { class: _vm.$config.icons.general.dataTable }),
              _c("br"),
              _c("span", { staticClass: "when-open" }, [_vm._v("Hide")]),
              _c("span", { staticClass: "when-closed" }, [_vm._v("Show")]),
              _vm._v(" Detail ")
            ]
          )
        ],
        1
      ),
      _c("b-collapse", { attrs: { id: "device-data" } }, [
        _c(
          "div",
          { staticClass: "device-list" },
          [
            _c("loading-box", { attrs: { loading: _vm.loading } }),
            _c("b-table", {
              ref: "deviceTable",
              attrs: {
                striped: "",
                hover: "",
                items: _vm.deviceList,
                fields: _vm.deviceFields,
                "select-mode": "single",
                "selected-variant": "success",
                "current-page": _vm.currentPage,
                "per-page": _vm.perPage,
                "td-class": "admin-device-table-data",
                "th-class": "admin-device-table-header"
              },
              scopedSlots: _vm._u([
                {
                  key: "head(selected)",
                  fn: function(row) {
                    return undefined
                  }
                },
                {
                  key: "cell(selected)",
                  fn: function(row) {
                    return [
                      _c("b-form-checkbox", {
                        on: {
                          change: function($event) {
                            return _vm.selectChange(row.item, $event)
                          }
                        },
                        model: {
                          value: row.item.selected,
                          callback: function($$v) {
                            _vm.$set(row.item, "selected", $$v)
                          },
                          expression: "row.item.selected"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "cell(geolimit_status)",
                  fn: function(row) {
                    return [
                      row.item.geolimit_status.is_active
                        ? _c("p", [_vm._v("On")])
                        : _vm._e(),
                      !row.item.geolimit_status.is_active
                        ? _c("p", [_vm._v("Off")])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "row-details",
                  fn: function(row) {
                    return [
                      _c(
                        "b-card",
                        [
                          _vm._v(" Device List Details "),
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm" },
                              on: { click: row.toggleDetails }
                            },
                            [_vm._v("Hide Details")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "cell(map)",
                  fn: function(row) {
                    return [
                      row.item.device_imei
                        ? _c(
                            "b-button",
                            {
                              staticClass: "button w-100",
                              on: {
                                click: function($event) {
                                  return _vm.deviceActionFleet(
                                    row.item.device_imei
                                  )
                                }
                              }
                            },
                            [_vm._v("Show")]
                          )
                        : _c(
                            "b-button",
                            {
                              staticClass: "button w-100",
                              on: {
                                click: function($event) {
                                  return _vm.deviceActionFleet(
                                    row.item.device_imei
                                  )
                                }
                              }
                            },
                            [_vm._v("Show")]
                          )
                    ]
                  }
                }
              ])
            }),
            _vm.loadingMoreDevices
              ? _c("p", [_vm._v("Loading more devices...")])
              : _vm._e(),
            !_vm.hideFilter
              ? _c("div", { staticClass: "green-divider" })
              : _vm._e(),
            !_vm.hidePagination
              ? _c(
                  "div",
                  { staticClass: "page-bar" },
                  [
                    _vm.totalRows >
                    Math.min(
                      _vm.pageOptions.map(function(x) {
                        return x.value
                      })
                    )
                      ? _c("b-form-select", {
                          staticClass: "page-select",
                          attrs: {
                            id: "perPageSelect",
                            size: "sm",
                            options: _vm.pageOptions
                          },
                          model: {
                            value: _vm.perPage,
                            callback: function($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage"
                          }
                        })
                      : _vm._e(),
                    _vm.totalRows > _vm.perPage
                      ? _c("b-pagination", {
                          staticClass: "page-buttons",
                          attrs: {
                            "total-rows": _vm.totalRows,
                            "per-page": _vm.perPage,
                            align: "fill",
                            size: "sm"
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }