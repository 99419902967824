var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stats content" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "sidebar sidebar-stats" },
        [
          _c("img", {
            staticClass: "sidebar-logo",
            attrs: { src: require("../assets/Pro-tekt-logo.png") }
          }),
          _c("stats-controls", {
            attrs: {
              "device-id": _vm.deviceId,
              date: _vm.date,
              period: _vm.period
            },
            on: {
              "stats-data-change": _vm.onStatsDataChange,
              devices: _vm.onDevicesChange,
              loading: _vm.onLoading
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "stats-view-container" },
        [
          _c(
            "b-row",
            _vm._l(_vm.report_schema, function(layout) {
              return _c(
                "b-col",
                {
                  key: layout.title,
                  staticClass: "stats-view",
                  attrs: {
                    sm: layout.size.sm,
                    md: layout.size.md,
                    lg: layout.size.lg
                  }
                },
                [
                  _c(
                    "b-card",
                    [
                      _c("b-card-header", [_vm._v(_vm._s(layout["title"]))]),
                      _c(
                        "b-card-body",
                        { staticClass: "summary-body" },
                        _vm._l(layout["components"], function(component) {
                          return _c(
                            "div",
                            { key: component.name },
                            [
                              component["component_type"] ==
                              "daily-travel-chart"
                                ? _c("daily-travel-chart", {
                                    attrs: {
                                      data: _vm.data,
                                      options: component["options"]
                                    }
                                  })
                                : _vm._e(),
                              component["component_type"] ==
                              "daily-travel-summary-table"
                                ? _c("stats-display-table", {
                                    attrs: { data: _vm.data }
                                  })
                                : _vm._e(),
                              component["component_type"] ==
                              "device-summary-table"
                                ? _c("device-table-user", {
                                    attrs: { devices: _vm.devices }
                                  })
                                : _vm._e(),
                              component["component_type"] ==
                              "device-daily-table"
                                ? _c("device-daily-table-user", {
                                    attrs: {
                                      devices: _vm.devices,
                                      deviceDaily: _vm.statsData["device-daily"]
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }